.header {
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  object-fit: contain;
  background-color: white;
  background: #ffffff;
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */
}
.header :hover{
  background: #dddddd;
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f1f1f1 97%,
    #e1e1e1 98%,
    #f6f6f6 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */
}

.header__navitem {
  margin: 30px;
}

.header__logo {
  object-fit: scale-down;
  padding: 4px;
  max-width: 190px;
}
.header__logo:hover {
  opacity: 90;
}
.text-link {
  text-decoration: none;
  color: #591e19;;
}

@media screen and (max-width: 600px) {
  .header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    vertical-align: middle;
    object-fit: contain;
    background-color: white;
    background: #ffffff;
    background: -moz-linear-gradient(
      top,
      #ffffff 0%,
      #f1f1f1 97%,
      #e1e1e1 98%,
      #f6f6f6 100%
    );
    background: -webkit-linear-gradient(
      top,
      #ffffff 0%,
      #f1f1f1 97%,
      #e1e1e1 98%,
      #f6f6f6 100%
    );
    background: linear-gradient(
      to bottom,
      #ffffff 0%,
      #f1f1f1 97%,
      #e1e1e1 98%,
      #f6f6f6 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */
  }
}