.contact {
  background-color: #591e19;
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: 50vh;
  color: white;
  align-items: center;
  justify-content: space-around;
}

.contact__left {
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .contact {
    background-color: #591e19;
    display: flex;
    flex-direction: column;
  }
  .contact__left {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}