.about {
  background-color: #591e19;
  display: flex;
  width: 100%;
  height: 1050px;
  color: white;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 30px;
}
.about__intro{
  
}

.teamsinfo {
  display: flex;
  justify-content: space-between;
}
.about_leadership {
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.about_volunteer {
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.about_previous_volunteer {
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.about_leadership_container {
  display: flex;
}

.about_volunteer_container {
  display: flex;
}
