.services {
  background-color: #591e19;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 1050px;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
