.footer {
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  object-fit: contain;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,e1e1e1+2,f1f1f1+2,f6f6f6+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #e1e1e1 2%,
    #f1f1f1 2%,
    #f6f6f6 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #e1e1e1 2%,
    #f1f1f1 2%,
    #f6f6f6 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #e1e1e1 2%,
    #f1f1f1 2%,
    #f6f6f6 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0 ); /* IE6-9 */
}

.footer__links {
  display: flex;
}

.text-link {
  text-decoration: none;
  color: #591e19;
}

.footer__links p {
  font-weight: 700;
}

.footer__links__left {
  margin: 20px;
}
.footer__links__right {
  margin: 20px;
}

.footer__links__right p,
.footer__links__left p {
  margin: 5px;
}
