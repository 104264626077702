.maincontent {
  background-color: #591e19;
  display: flex;
  width: 100%;
  height: 450px;
}

.maincontent__left {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}

.maincontent__left__top1 {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.maincontent__left__down1 {
  display: flex;
  justify-content: space-evenly;
  align-content: center;
}

.srinivasa2 {
  object-fit: contain;
}

.img__rounded {
  margin: 20px;
  background-color: #fff;
  padding: 4px;
  max-width: 150px;
  max-height: 180px;
  border-radius: 50%;
}

.durga {
  object-fit: contain;
}

.saibaba {
  object-fit: contain;
}

.srinivasa {
  object-fit: contain;
}

.kalabhairav {
  object-fit: contain;
}

.maincontent__right {
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome__note,
.welcome__title {
  color: azure;
  margin: 5px;
}

@media screen and (max-width: 600px) {
  .maincontent {
    background-color: #591e19;
    display: flex;
    flex-direction: column;
  }
}