.donate {
  background-color: #591e19;
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 450px;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.donate > p {
  margin: 50px;
}
